import { computed, defineComponent } from 'vue';
export default defineComponent({
    props: {
        url: {
            type: String,
            required: false,
            default: null
        },
        label: {
            type: String,
            required: true
        },
        isTargetBlank: {
            type: Boolean,
            default: false
        },
        isLogotype: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const target = computed(() => {
            return props.isTargetBlank ? '_blank' : '_self';
        });
        return { target };
    }
});
