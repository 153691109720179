import { defineComponent, onMounted, ref } from 'vue';
import { getLoggedAdmin, logoutAdmin } from '@/assets/ts/helpers/admin';
import HeaderLink from '@/components/layout/header/header-desktop/HeaderDesktopLink.vue';
import HeaderLogoImage from '@/components/layout/header/header-desktop/HeaderLogoImage.vue';
export default defineComponent({
    components: {
        HeaderLogoImage,
        HeaderLink
    },
    props: {
        adminSide: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup() {
        const loggedAdmin = ref(null);
        onMounted(() => {
            updateAdminInfo();
        });
        function updateAdminInfo() {
            loggedAdmin.value = getLoggedAdmin();
        }
        return { loggedAdmin, logoutAdmin, updateAdminInfo };
    }
});
